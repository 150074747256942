import BaseApi from '@/includes/services/BaseApi'
import { OutputData } from "@editorjs/editorjs"

export class FlowStatisticsService {

    static getFlowConnectionsStatistics(type: string, body: { board_key: string, flow_key: string, begin: string, end: string }): Promise<{data: Array<any>}> {
        return BaseApi.sendRequest(type, 'getflowconnectionsstatistics', body)
    }

    static getFlowConnectionStatistics(type: string, body: { board_key: string, output: number, input: number, flow_key: string, begin: string, end: string }): Promise<{data: Array<any>}> {
        return BaseApi.sendRequest(type, 'getflowconnectionstatistics', body)
    }

    static getFlowUsersStatistics(type: string, body: { board_key: string, user: number, flow_key: string, begin: string, end: string }): Promise<{data: Array<any>}> {
        return BaseApi.sendRequest(type, 'getFlowUsersStatistics', body)
    }

    // static getArticle(type: string, body: { board_key: string, key: string }): Promise<any> {
    //     return BaseApi.sendRequest(type, 'GetArticle', body)
    // }
}