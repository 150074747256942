import { FlowStatisticsService } from '@/includes/services/FlowStatisticsService'
import store from '@/store/store'
import router from '@/router'
import { errorNotification } from '@/includes/services/NotificationService'
import { FlowsService } from '@/includes/services/FlowService'

import { FlowStatistics } from 'piramis-base-components/src/components/Flow/new/logic/FlowStatistics'

import { EditorView } from 'rete/types/view'

export default class PresscodeFlowStatistics extends FlowStatistics {
    constructor(editorView: EditorView) {
        super(editorView)
    }

    getUserInfo(userData: string): Promise<any> {
        return FlowsService.getUsersInfo('tg', { board_key: store.getters.activeBoard.board, values: [ userData ] })
            .then(({ data }) => data)
            .catch(errorNotification)
    }

    reqFlowUserStatistics(user: number): Promise<Array<any>> {
        return FlowStatisticsService.getFlowUsersStatistics('tg', {
            user,
            begin: this.statSetting.from,
            end: this.statSetting.to,
            board_key: store.getters.activeBoard.board,
            flow_key: router.app.$route.query['flow_key'].toString()
        })
            .then((res) => res.data)
            .catch(error => {
                errorNotification(error)

                return []
            })
    }

    reqConnectionStatistics(inputKey: number, output: number) {
        return FlowStatisticsService.getFlowConnectionStatistics('tg', {
            input: inputKey,
            output,
            begin: this.statSetting.from,
            end: this.statSetting.to,
            board_key: store.getters.activeBoard.board,
            flow_key: router.app.$route.query['flow_key'].toString()
        })
            .then((res) => res.data)
            .catch(error => {
                errorNotification(error)

                return []
            })
    }

    reqConnectionsStatistics(): Promise<Array<any>> {
        return FlowStatisticsService.getFlowConnectionsStatistics('tg', {
            begin: this.statSetting.from,
            end: this.statSetting.to,
            board_key: store.getters.activeBoard.board,
            flow_key: router.app.$route.query['flow_key'].toString()
        })
            .then((res) => res.data)
            .catch(error => {
                errorNotification(error)

                return []
            })
    }
}