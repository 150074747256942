import CreateInviteLinkSidebar
    from '@/components/Flow/Actions/CreateInviteLink/ui/Sidebar/CreateInviteLinkSidebar.vue'
import CreateInviteLinkSampleNode
    from '@/components/Flow/Actions/CreateInviteLink/ui/Node/CreateInviteLinkNode.vue'
import { CreateInviteLinkAction } from '../../Actions/CreateInviteLink/logic/CreateInviteLinkAction'
import { CreateInviteLinkActionProxy } from '../../Actions/CreateInviteLink/logic/CreateInviteLinkActionProxy'
import store from '@/store/store'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import router from '@/router'
import FormAnswerButtonBuilder  from '@/components/ProjectButtons/New/FormAnswerButton'
import { JoinRequestAction } from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestAction'
import { JoinRequestActionProxy } from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestActionProxy'
import JoinRequestSidebar from '@/components/Flow/Actions/JoinRequest/ui/Sidebar/JoinRequestSidebar.vue'
import JoinRequestNode from '@/components/Flow/Actions/JoinRequest/ui/Node/JoinRequestNode.vue'
import PopupButtonButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/PopupButton'

import ActionNodeBuilder
    from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'
import RunFlowNodeButtonBuilder
    from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/RunFlowNodeButton'
import LinkButtonBuilder
    from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link'
import ShareButtonBuilder
    from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share'
import FlowFormButtonBuilder
    from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/FlowFormButton'
import { PlaceholdersType } from 'piramis-base-components/src/components/Editor/components/Toolbar/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'
import { ActionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/types'

import i18n from 'vue-i18n'
import customMediaTypes from '@/components/MessageEditorCustomAttachments/Properties/customMediaTypes'
import RedirectButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RedirectButton'
import {
    DeleteLastMessageAction
} from '@/components/Flow/Actions/DeleteLastMessageAction/logic/DeleteLastMessageAction'
import {
    DeleteLastMessageActionProxy
} from '@/components/Flow/Actions/DeleteLastMessageAction/logic/DeleteLastMessageActionProxy'
import DeleteLastMessageActionSidebar
    from '@/components/Flow/Actions/DeleteLastMessageAction/ui/Sidebar/DeleteLastMessageActionSidebar.vue'
import DeleteLastMessageActionNode
    from '@/components/Flow/Actions/DeleteLastMessageAction/ui/Node/DeleteLastMessageActionNode.vue'
import { BanAction } from '@/components/Flow/Actions/Ban/logic/BanAction'
import { BanActionProxy } from '@/components/Flow/Actions/Ban/logic/BanActionProxy'
import BanActionSidebar from '@/components/Flow/Actions/Ban/ui/Sidebar/BanActionSidebar.vue'
import BanActionNode from '@/components/Flow/Actions/Ban/ui/Node/BanActionNode.vue'
import { PublishAction } from '@/components/Flow/Actions/Publish/logic/PublishAction'
import { PublishActionProxy } from '@/components/Flow/Actions/Publish/logic/PublishActionProxy'
import PublishActionSidebar from '@/components/Flow/Actions/Publish/ui/Sidebar/PublishActionSidebar.vue'
import PublishActionNode from '@/components/Flow/Actions/Publish/ui/Node/PublishActionNode.vue'

export class ActionNode extends ActionNodeBuilder {
    constructor(i18n: i18n, readonly: boolean) {
        let props: ActionNodeProps

        if (readonly) {
            props = {
                createNewLabel: () => {
                    throw new Error('Attempt to create a label with a screenshot')
                },
                createNewProperty: () => {
                    throw new Error('Attempt to create a property with a screenshot')
                },
                getLabels: () => ({}),
                getProperties: () => [],

                placeholders: () => ({
                    type: PlaceholdersType.Ones,
                    placeholders: []
                }),
                buttonHelpView: EditorButtonsHelpView,
                availableButtonsTypes: [],
                target: '',
                targetOptions: () => [],
                targets: () => [],
                usersOptions: () => [ ],
                externalActions: [ {
                    type: ActionTypes.CreateInviteLink,
                    action: CreateInviteLinkAction,
                    proxy: CreateInviteLinkActionProxy,
                    sidebar: CreateInviteLinkSidebar,
                    node: CreateInviteLinkSampleNode,
                },
                    {
                        type: ActionTypes.JoinRequest,
                        action: JoinRequestAction,
                        proxy: JoinRequestActionProxy,
                        sidebar: JoinRequestSidebar,
                        node: JoinRequestNode,
                    },
                    {
                        type: ActionTypes.DeleteLastMessageAction,
                        action: DeleteLastMessageAction,
                        proxy: DeleteLastMessageActionProxy,
                        sidebar: DeleteLastMessageActionSidebar,
                        node: DeleteLastMessageActionNode,
                    },
                    {
                        type: ActionTypes.Ban,
                        action: BanAction,
                        proxy: BanActionProxy,
                        sidebar: BanActionSidebar,
                        node: BanActionNode,
                    },
                    {
                        type: ActionTypes.Publish,
                        action: PublishAction,
                        proxy: PublishActionProxy,
                        sidebar: PublishActionSidebar,
                        node: PublishActionNode,
                    } ],
            }
        } else {
            props = {
                createNewLabel: (label) => store.dispatch('createLabel', label),
                createNewProperty: (path: string, name: string, parent?: number) => store.dispatch('addBoardProperty', { path, name, parent } ),
                getLabels: () => store.getters.labels,
                getProperties: () => store.getters.propertiesList,

                placeholders: () => store.getters.flowPlaceholders,
                buttonHelpView: EditorButtonsHelpView,
                customMediaTypes: customMediaTypes,
                availableButtonsTypes: [
                    new RunFlowNodeButtonBuilder(i18n),
                    new LinkButtonBuilder(i18n),
                    new ShareButtonBuilder(i18n),
                    new FormAnswerButtonBuilder(i18n, () => store.getters.formsSelectOptions, () => {
                        router.push({
                            name: 'Forms',
                            params: {
                                id: router.currentRoute.params[ EntityTypes.BOT_ID ]
                            }
                        })
                    }),
                    new FlowFormButtonBuilder(i18n, () => store.getters.formsSelectOptions, () => {
                        router.push({
                            name: 'Forms',
                            params: {
                                id: router.currentRoute.params[ EntityTypes.BOT_ID ]
                            }
                        })
                    }),
                    new PopupButtonButtonBuilder(i18n),
                    new RedirectButtonBuilder(i18n)
                ],
                target: store.state.boardsState.activeBoard!.board,

                targetOptions: () => store.getters.boardTargetOptions,
                targets: () => store.getters.botTargets,
                usersOptions: () => store.getters.boardAdmins,

                externalActions: [ {
                    type: ActionTypes.CreateInviteLink,
                    action: CreateInviteLinkAction,
                    proxy: CreateInviteLinkActionProxy,
                    sidebar: CreateInviteLinkSidebar,
                    node: CreateInviteLinkSampleNode,
                },
                {
                    type: ActionTypes.JoinRequest,
                    action: JoinRequestAction,
                    proxy: JoinRequestActionProxy,
                    sidebar: JoinRequestSidebar,
                    node: JoinRequestNode,
                },
                    {
                        type: ActionTypes.DeleteLastMessageAction,
                        action: DeleteLastMessageAction,
                        proxy: DeleteLastMessageActionProxy,
                        sidebar: DeleteLastMessageActionSidebar,
                        node: DeleteLastMessageActionNode,
                    },
                    {
                        type: ActionTypes.Ban,
                        action: BanAction,
                        proxy: BanActionProxy,
                        sidebar: BanActionSidebar,
                        node: BanActionNode,
                    },
                    {
                        type: ActionTypes.Publish,
                        action: PublishAction,
                        proxy: PublishActionProxy,
                        sidebar: PublishActionSidebar,
                        node: PublishActionNode,
                    }
                ],
            }
        }

        super(i18n, props)
    }

}