import CheckLimitedSubscriptionNodeBuilder
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Subscription/CheclLimitedSubscription/logic/CheckLimitedSubscriptionNode'
import i18n from 'vue-i18n'
import { CheckLimitedSubscriptionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Subscription/CheclLimitedSubscription/logic/types'
import store from '@/store/store'
export class CheckLimitedSubscriptionNode extends CheckLimitedSubscriptionNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: CheckLimitedSubscriptionNodeProps

    if (readonly) {
      props = {
        targets: () => []
      }
    } else {
    props = {
      targets: () => {
        const board = store.state.boardsState.activeBoard

        if (board) {
          return store.getters.activeBoardChannels.map(({ title, username, id }) => ({
            title,
            id,
            login: username
          }))
        }
        return []
      }
    }
    }

    super(i18n, props)
  }
}