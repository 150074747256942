const KEY = 'pc_onb'

export enum OnboardTokens {
    flow = 'flow'
}

export class OnboardingTokenHelper {
    private static getTokensRecordFromLocalStorage(): Partial<Record<OnboardTokens, boolean>> | null {
        if (localStorage) {
            const item = localStorage.getItem(KEY)

            if (item) {
                return JSON.parse(item)
            } else {
                return {
                    [OnboardTokens.flow]: false
                }
            }
        }

        return null
    }

    static getTokenValue(token: OnboardTokens) {
        const record = OnboardingTokenHelper.getTokensRecordFromLocalStorage();

        if (record) {
            return record[token]
        }
    }

    static setTokenValue(value: Partial<Record<keyof typeof OnboardTokens, boolean>>) {
        const record = OnboardingTokenHelper.getTokensRecordFromLocalStorage();

        if (record && value.flow !== undefined) {
            record['flow'] = value.flow
        }

        if (localStorage) {
            localStorage.setItem(KEY, JSON.stringify(record))
        }
    }
}