import store from '@/store/store'
import router from "@/router";
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import RunFlowButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RunFlowButton'
import customMediaTypes from '@/components/MessageEditorCustomAttachments/Properties/customMediaTypes'

import EditLastMessageNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/EditLastMessage/logic/EditLastMessageNode'
import { EditLastMessageNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/EditLastMessage/logic/types'
import RunFlowNodeButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/RunFlowNodeButton'
import LinkButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link'
import ShareButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share'
import FlowFormButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/FlowFormButton'
import { PlaceholdersType } from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import i18n from 'vue-i18n'
import PopupButtonButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/PopupButton'
import RedirectButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RedirectButton';

export class EditLastMessageNode extends EditLastMessageNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: EditLastMessageNodeProps

    if (readonly) {
      props = {
        availableButtonsTypes: [],
        buttonHelpView: EditorButtonsHelpView,
        placeholders: () => ({
          type: PlaceholdersType.Ones,
          placeholders: []
        }),
        target: '',
      }
    } else {
    props = {
      availableButtonsTypes: [
        new RunFlowNodeButtonBuilder(i18n),
        new LinkButtonBuilder(i18n),
        new RunFlowButtonBuilder(i18n, () => store.getters.flowOptions),
        new ShareButtonBuilder(i18n),
        new FlowFormButtonBuilder(i18n, () => store.getters.formsSelectOptions, () => {
          router.push({
            name: 'Forms',
            params: {
              id: router.currentRoute.params[ EntityTypes.BOT_ID ]
            }
          })
        }),
        new PopupButtonButtonBuilder(i18n),
        new RedirectButtonBuilder(i18n)
      ],
      buttonHelpView: EditorButtonsHelpView,
      placeholders: () => store.getters.flowPlaceholders,
      target: store.state.boardsState.activeBoard!.board,
      customMediaTypes: customMediaTypes,
    }
    }

    super(i18n, props)
  }
}