







































































































































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import InviteLinkEditorMessage from '../HelpMessages/editor/InviteLinkEditorMessage.vue'
import { FieldsShowTo, InviteLinkApproveType, InviteLinkWithKey } from '@/includes/logic/InviteLinks/types'
import CaptchaMessageHelper from "@/mixins/CaptchaMessageHelper";

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import ActivationActionData
  from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction/ActivationActionData.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { UserActivationActionTypeEnum } from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction';

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      InviteLinkApproveType,
      EditorButtonsHelpView,
      InviteLinkEditorMessage,
      MediaEditorMode,
    }
  },
  components: {
    MultiMessageEditorWithMediaInput,
    ActivationActionData
  }
})
export default class InviteLinkActionSetting extends Mixins(UseFields, InputSetups, CaptchaMessageHelper) {
  @VModel() model!: InviteLinkWithKey['config']

  @Prop({ type: Object, required: false, default: null }) fieldsShowTo!: FieldsShowTo | null

  @Prop({ type: Object, required: false, default: null }) readonly !: FieldsShowTo | null

  @Prop() channelId!: number

  @Prop({ type: Array, required: true }) approveTypeOptions!: Array<SelectOption>

  get limitTypes() {
    if (this.$store.state.boardsState.activeBoard?.license_expired) {
      return [ UserActivationActionTypeEnum.Flow ]
    }
  }

  get isManualApprove() {
    return this.model.approve === InviteLinkApproveType.Manual
  }

  get isAutoApprove() {
    return this.model.approve === InviteLinkApproveType.Auto
  }

  isVisible(field: keyof FieldsShowTo) {
    return (this.fieldsShowTo && this.fieldsShowTo[field]) ?? true
  }

  isReadonly(field: keyof FieldsShowTo) {
    return (this.readonly && this.readonly[field]) ?? false
  }

  approveSetter(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (val === InviteLinkApproveType.None) {
        this.$delete(this.model, 'message')
      } else {
        if (val === InviteLinkApproveType.Auto) {
          this.$delete(this.model, 'user_reject_action')
        }

        if (!this.model.message) {
          this.$set(this.model, 'message', [ {
            attachments: [],
            text: '',
            buttons: [],
            send_after: 0,
            remove_previous: false,
            pin: false,
            disable_link_preview: false,
            disable_notify: false,
          } ])
        }
      }

      this.model.limit = 0
      this.model.approve = val
    }

    return arg
  }

  get hasCaptcha() {
    if (this.model.message) {
      return this.hasCaptchaButton(this.model.message)
    }

    return false
  }

  get captchaSuccessActionPrefix() {
    const fieldName = 'user_captcha_success_action'

    if (this.isAutoApprove) {
      return `auto_${ fieldName }_`
    } else if (this.isManualApprove) {
      return `manual_${ fieldName }_`
    } else {
      return `${ fieldName }_`
    }
  }

  gotoFlows(): void {
    this.$router.push({
      name: 'Flows_List',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }

}
