import MessageEditorWithMediaData from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import { UserActivationAction } from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction';

export type BaseInviteLinkStatField = {
    enter: number
    leave: number
    kick: number
}

export type InviteLinkConfig = {
    approve: InviteLinkApproveType
    price: number
    limit: number
    disable_auto_attack: boolean
    force_approve?: boolean
    expire?: string
    message?: Array<MessageEditorWithMediaData>
    user_accept_action?: UserActivationAction | null
    user_reject_action?: UserActivationAction | null
    user_captcha_success_action?: UserActivationAction | null
}

export type InviteLink = {
    title: string
    tags: Array<string> | null
    config: InviteLinkConfig
    link: string
} & BaseInviteLinkStatField

export type InviteLinkWithKey = {
    key: string
} & InviteLink

export type InviteLinkToCreate = Omit<InviteLink, 'link' | keyof BaseInviteLinkStatField>

export type InviteLinkToEdit = InviteLinkToCreate

export type LocalInviteLink = Pick<InviteLinkWithStat, 'items'> & {
    data: InviteLinkWithKey,
    chart: {
        series: ApexChartSeries
    }
}

// statistics
export type InviteLinkStatRecord = {
    time: string
    first_enter: number
    first_leave: number
}

export type InviteLinkStatHourReq = {
    interval_type: 'Full'
    from: string
    to: string
}

export type InviteLinkStatDayReq = {
    interval_type: 'Short'
}

export type InviteLinkStatReq = { interval: InviteLinkStatHourReq | InviteLinkStatDayReq }

export type InviteLinkWithStat = {
    items?: Array<InviteLinkStatRecord>
    data: InviteLinkStatisticsData
}

export type InviteLinkStatisticsData = Pick<InviteLink, 'title' | 'config' | 'leave' | 'link'> & {
    id: number
    totalActive: number
    totalEnter: number
    totalLeave: number
    totalKicked: number
    sourceId: number
    searchLink: string
} & Pick<InviteLinkConfig, 'price'>

export type InviteLinksWithStat = Array<InviteLinkWithStat>

export enum InviteLinkApproveType {
    None = 'None',
    Manual = 'Manual',
    Auto = 'Auto',
    Disabled = 'Disabled',
}

export type FieldsShowTo = Partial<Record<keyof InviteLink['config'], boolean>>
