































import PropertiesMediaFileData from './PropertiesMediaFileData';
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi';
import MediaManager from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/logic/MediaManager';
import { FileType } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types';
import { SelectOption } from 'piramis-base-components/src/logic/types';
import CreateNewProperty from 'piramis-base-components/src/components/BotProperties/components/Properties/CreateNewProperty.vue';
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { PropertyFieldType } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

import { plainToInstance } from 'class-transformer'
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CreateNewProperty
  },
  data() {
    return {
      PropertyFieldType
    }
  }
})
export default class PropertiesSelectView extends Mixins(UseFields, InputSetups) {

  @Prop() mediaManager!: MediaManager

  value = null;

  newPropertyName = ''

  isOpen = false

  @Emit()
  closeModal() {
    return ''
  }

  get properties() {
    return this.$store.state.boardsState.activeBoard.properties.filter(p => p.path === 'Files')
      .map(p => ({
        label: p.name,
        value: p.id
      }))
  }

  setPropertyIndex(data) {
    this.value = data.id;
    this.setAttachment(data.id)
  }

  createNewProperty(path: string, name: string, parent?: number) {
    return this.$store.dispatch('addBoardProperty', { path, name, parent } )
  }

  createNewPropertyHandler(option: SelectOptionData): void {
    this.newPropertyName = option?.label
    this.isOpen = true
  }

  onInput({ value }: SelectOption) {
    if (value) {
      this.setAttachment(value)
    }
  }

  setAttachment(value: number) {
    this.mediaManager.attachments.push(plainToInstance(PropertiesMediaFileData, {
      file: `property:${ value }`,
      type: FileType.Custom,
    }))

    this.mediaManager.updateModel()

    this.closeModal()
  }
}

