import RunFlowNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Additionally/RunFlow/logic/RunFlowNode'
import i18n from 'vue-i18n'
import { RunFlowNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Additionally/RunFlow/logic/types'
import store from '@/store/store'

export class RunFlowNode extends RunFlowNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: RunFlowNodeProps

    if (readonly) {
      props = {
        getFlows: () => [],
        usersOptions: () => [],
        properties: () => [],
        createNewProperty: () => Promise.resolve([]),
      }
    } else {
      props = {
        getFlows: () => store.getters.flowOptions,
        usersOptions: () => store.getters.boardAdmins,
        properties: () => store.getters.propertiesList,
        createNewProperty: (path: string, name: string, parent?: number) => store.dispatch('addBoardProperty', { path, name, parent } ),
      }
    }

    super(i18n, props)
  }

}