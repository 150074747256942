




















import { Vue, Component, Emit } from 'vue-property-decorator';

@Component({})
export default class ConditionItemActions extends Vue {
  @Emit()
  editClick() {
    return
  }

  @Emit()
  deleteClick() {
    return
  }
}
