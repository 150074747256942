import InfoLimitedSubscriptionNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Subscription/InfoLimitedSubscription/logic/InfoLimitedSubscriptionNode'
import { InfoLimitedSubscriptionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Subscription/InfoLimitedSubscription/logic/types'
import i18n from 'vue-i18n'
import store from '@/store/store'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import RunFlowNodeButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/RunFlowNodeButton'
import LinkButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link'
import ShareButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share'
import FlowFormButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/FlowFormButton'
import router from "@/router";
import { PlaceholdersType } from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import customMediaTypes from '@/components/MessageEditorCustomAttachments/Properties/customMediaTypes'
import PopupButtonButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/PopupButton'
import RedirectButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RedirectButton'

export class InfoLimitedSubscriptionNode extends InfoLimitedSubscriptionNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: InfoLimitedSubscriptionNodeProps

    if (readonly) {
      props = {
        availableButtonsTypes: [],
        placeholders: () => ({
          type:  PlaceholdersType.Ones,
          placeholders: []
        }),
        buttonHelpView: EditorButtonsHelpView,
        targets: () => [],
        target: '',
      }
    } else {
    props = {
      customMediaTypes: customMediaTypes,
      availableButtonsTypes: [
        new RunFlowNodeButtonBuilder(i18n),
        new LinkButtonBuilder(i18n),
        new ShareButtonBuilder(i18n),
        new FlowFormButtonBuilder(i18n, () => store.getters.formsSelectOptions, () => {
          router.push({
            name: 'Forms',
            params: {
              id: router.currentRoute.params[ EntityTypes.BOT_ID ]
            }
          })
        }),
        new PopupButtonButtonBuilder(i18n),
        new RedirectButtonBuilder(i18n)
      ],
      placeholders: () => store.getters.flowPlaceholders,
      buttonHelpView: EditorButtonsHelpView,
      targets: () => {
        const board = store.state.boardsState.activeBoard

        if (board) {
          return store.getters.activeBoardChannels.map(({ title, username, id }) => ({
            title,
            id,
            login: username
          }))
        }
        return []
      },
      target: store.state.boardsState.activeBoard!.board,
    }
    }

    super(i18n, props)
  }
}