import { CreateInviteLinkAction } from "./CreateInviteLinkAction"

import { ActionNodeActionProxy } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions"
import { ActionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/types'

export class CreateInviteLinkActionProxy extends ActionNodeActionProxy {
    action: CreateInviteLinkAction
    private nodeProps: ActionNodeProps

    constructor(action: CreateInviteLinkAction, nodeProps: ActionNodeProps) {
        super()
        this.nodeProps = nodeProps
        this.action = action
    }

    getModel(): CreateInviteLinkAction {
        return this.action
    }

    isActionValid() {
        return this.action.property_id !== null && this.action.channel_id !== null
    }
}
