























































import Editor from '@/components/Flow/Editor'
import { FlowsService } from '@/includes/services/FlowService'
import { CreateInviteLinkAction } from '@/components/Flow/Actions/CreateInviteLink/logic/CreateInviteLinkAction'
import { JoinRequestAction } from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestAction'
import { OnboardingTokenHelper, OnboardTokens } from '@/includes/logic/OnboardingTokenHelper'
import { DeleteLastMessageAction } from '@/components/Flow/Actions/DeleteLastMessageAction/logic/DeleteLastMessageAction'
import { BanAction } from '@/components/Flow/Actions/Ban/logic/BanAction'
import { PublishAction } from '@/components/Flow/Actions/Publish/logic/PublishAction'

import Flow from 'piramis-base-components/src/components/Flow/new/Flow.vue'
import CustomEditor from 'piramis-base-components/src/components/Flow/new/logic/Editor/CustomEditor'
import { FlowConfig } from 'piramis-base-components/src/components/Flow/new/types'
import { ActionNodeActionTypesFactory } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'
import { StartNode } from 'piramis-base-components/src/components/Flow/new/logic/Editor/StartNode/StartNode'
import isMobile from 'piramis-js-utils/lib/isMobile'

import { Component } from 'vue-property-decorator'
import { plainToInstance } from 'class-transformer'
import Vue from 'vue'
import { Modal } from 'ant-design-vue'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import { ProjectFlowConfig } from "@/components/Flow/Accessor/types";
import FlowAccessor from "@/components/Flow/Accessor/FlowAccessor.vue";

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  components: {
    FlowAccessor,
    Flow,
    isMobile
  },
})
export default class NewFlow extends Vue {
  editor: CustomEditor | null = null

  isReady = false

  isSaveFlowModalOpen = false

  nextCallback: (() => void) | null = null

  registerProjectActions() {
    ActionNodeActionTypesFactory.SetModelFactory(ActionTypes.CreateInviteLink, CreateInviteLinkAction)
    ActionNodeActionTypesFactory.SetModelFactory(ActionTypes.JoinRequest, JoinRequestAction)
    ActionNodeActionTypesFactory.SetModelFactory(ActionTypes.DeleteLastMessageAction, DeleteLastMessageAction)
    ActionNodeActionTypesFactory.SetModelFactory(ActionTypes.Ban, BanAction)
    ActionNodeActionTypesFactory.SetModelFactory(ActionTypes.Publish, PublishAction)
  }

  driverObject = driver()

  init(container: HTMLElement): void {
    this.registerProjectActions()

    if (this.$route.name !== 'Flow_Screenshot') {
      const boardKey = this.$store.state.boardsState.activeBoard!.board

      FlowsService.newGetFlow('tg', { board_key: boardKey, flow_key: this.$route.query.flow_key as string })
        .then((config) => {
          this.editor = new Editor(config.instance, container, this.$i18n)

          this.editor.init()
            .then(() => {
              this.runOnboarding()
            })
        })
    } else {
      FlowsService.getFlowThumbnailData('tg', { request: this.$route.params.request })
        .then(({ flow }) => {
          this.editor = new Editor(plainToInstance(ProjectFlowConfig, flow), container, this.$i18n)

          this.editor.init()
        })
    }
  }

  runOnboarding() {
    const clearOnboardingQuery = () => {
      const query = Object.assign({}, this.$route.query);

      delete query.onboarding;

      this.$router.replace({ query });
    }

    if (this.editor && !OnboardingTokenHelper.getTokenValue(OnboardTokens.flow) && this.$route.query.onboarding) {
      this.$confirm({
        icon: 'info-circle',
        okText: "Начать",
        cancelText: "Пропустить",
        title: this.$t('flow_onboarding_step_1_title').toString(),
        content: this.$t('flow_onboarding_step_1_text').toString(),
        onOk: () => {
          const wrapper = document.createElement('div')
          const flowDoc = document.getElementById('flow');
          const minimap = document.querySelector('.minimap')

          const startNode = this.editor!.nodes.find(n => n instanceof StartNode)

          const defaultOutput = document.querySelector('.default_output')
          const defaultOutputWrapperForButton = defaultOutput?.parentElement?.parentElement

          if (minimap) {
            minimap.classList.add('hidden')
          }

          wrapper.classList.add('wrapper', 'absolute')

          wrapper.style.top = '50%'
          wrapper.style.left = '50%'
          wrapper.style.height = '50vh'
          wrapper.style.width = '90vw'
          wrapper.style.transform = 'translate(-50%, -50%)'

          this.editor!.toggleAreaStateFields({ clickable: false, movable: false })

          if (flowDoc) {
            flowDoc.append(wrapper)
          }

          this.driverObject.setConfig({
            showProgress: true,
            allowClose: false,
            nextBtnText: this.$t('onboarding_next_button_title').toString(),
            prevBtnText: this.$t('onboarding_prev_button_title').toString(),
            doneBtnText: this.$t('onboarding_done_button_title').toString(),
            progressText: this.$t('onboarding_progress_text').toString(),
            onDestroyed: () => {
              wrapper.remove();
              clearOnboardingQuery()

              if (minimap) {
                minimap.classList.remove('hidden')
              }
            },
            steps: [
              {
                element: '.wrapper',
                popover: {
                  title: this.$t('flow_onboarding_step_2_title').toString(),
                  description: this.$t('flow_onboarding_step_2_text').toString(),
                  showButtons: [ 'next' ],
                }
              },
              {
                element: document.querySelector('.node'),
                popover: {
                  title: this.$t('flow_onboarding_step_3_title').toString(),
                  description: this.$t('flow_onboarding_step_3_text').toString(),
                }
              },
              ...defaultOutputWrapperForButton ? [ {
                element: defaultOutputWrapperForButton,
                popover: {
                  title: this.$t('flow_onboarding_step_4_title').toString(),
                  description: this.$t('flow_onboarding_step_4_text').toString(),
                }
              } ] : [],
              {
                element: '.socket-item.main_input',
                popover: {
                  title: this.$t('flow_onboarding_step_5_title').toString(),
                  description: this.$t('flow_onboarding_step_5_text').toString(),
                }
              },
              {
                element: (startNode as any).vueContext.$el,
                popover: {
                  title: this.$t('flow_onboarding_step_6_title').toString(),
                  description: this.$t('flow_onboarding_step_6_text').toString(),
                }
              },
              {
                element: '.main-path',
                popover: {
                  title: this.$t('flow_onboarding_step_7_title').toString(),
                  description: this.$t('flow_onboarding_step_7_text').toString(),
                }
              },
              {
                element: '#save-flow-button',
                popover: {
                  title: this.$t('flow_onboarding_step_8_title').toString(),
                  description: this.$t('flow_onboarding_step_8_text').toString(),
                }
              },
              {
                element: '#run-flow-button',
                popover: {
                  title: this.$t('flow_onboarding_step_9_title').toString(),
                  description: this.$t('flow_onboarding_step_9_text').toString(),
                }
              },
              {
                element: '.wrapper',
                popover: {
                  description: isMobile() ? this.$t('flow_onboarding_step_10_text_mobile').toString() : this.$t('flow_onboarding_step_10_text_main').toString(),
                  side: 'over',
                  align: 'center',
                  showButtons: [ 'next' ],
                  onNextClick: () => {
                    OnboardingTokenHelper.setTokenValue({ flow: true })
                    this.driverObject.moveNext()

                    this.editor!.toggleAreaStateFields({ clickable: true, movable: true })
                  }
                },
              },
            ]
          });

          this.driverObject.drive();
        },
        onCancel: () => {
          clearOnboardingQuery()
          OnboardingTokenHelper.setTokenValue({ flow: true })
        }
      })
    }
  }

  async mounted(): Promise<void> {
    window.addEventListener('beforeunload', this.checkForChange)

    const promises: Array<Promise<any>> = []

    if (this.$route.name !== 'Flow_Screenshot') {
      if (!this.$store.state.formsState.forms) {
        promises.push(this.$store.dispatch('getForms'))
      }

      if (!this.$store.state.flowsState.flows) {
        promises.push(this.$store.dispatch('requestFlows'))
      }

      if (!this.$store.state.shopState.cashRegisters) {
        promises.push(this.$store.dispatch('getCashRegisters'))
      }

      if (!this.$store.state.shopState.products) {
        promises.push(this.$store.dispatch('getProducts'))
      }
    }

    await Promise.allSettled(promises)

    this.isReady = true
  }

  checkForChange(e) {
    Modal.destroyAll();
    e.preventDefault()

    if(this.editor?.isFlowChanged()) {
      return e.returnValue = this.$t('flow_save_before_leave_content').toString()
    }
  }

  destroyed() {
    window.removeEventListener('beforeunload', this.checkForChange)

    this.driverObject.destroy()
  }

  saveFlowAndLeave() {
    this.editor?.saveFlow()
      .then(() => {
        if (this.nextCallback) {
          this.nextCallback()
        }
      })
  }

  beforeRouteLeave(flow, to, next): void {
    Modal.destroyAll();

    if (this.editor?.isFlowChanged()) {
      this.isSaveFlowModalOpen = true
      this.nextCallback = next
    } else {
      next()
    }
  }
}
