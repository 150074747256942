




import { BanActionProxy } from '../../logic/BanActionProxy'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
})
export default class BanActionSidebar extends Vue {
  @Prop() action!: BanActionProxy
}
