import state from '@/store/state'
import {
  SendMessageNode,
  ActionNode,
  SendInvoiceNode,
  EditLastMessageNode,
  ForwardMessageNode,
  ConditionsCustomNodeBuilder,
  DelayNode,
  CheckSubscriptionNode,
  CheckLimitedSubscriptionNode,
  CreateLimitedSubscriptionCustomNodeBuilder,
  InfoLimitedSubscriptionNode,
  RunFlowNode,
  HttpRequestNodeCustomBuilder
} from './Nodes'
import FlowMainHint from '@/components/HelpMessages/flow/FlowMainHint.vue'
import i18n from '@/i18n/i18n'
import { FlowsService } from '@/includes/services/FlowService'
import store from '@/store/store'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import router from "@/router";
import PresscodeFlowStatistics from '@/components/Flow/PresscodeFlowStatistics'
import { ProjectFlowConfig } from "@/components/Flow/Accessor/types";
import IntegrationNodeBuilderCustom from "@/components/Flow/Nodes/Additionally/IntegrationCustom/IntegrationCustom";

import CustomEditor from 'piramis-base-components/src/components/Flow/new/logic/Editor/CustomEditor'
import { GetUsersInfo } from 'piramis-base-components/src/models/getUserInfo/GetUserInfo'
import { ChannelInfoResponse } from 'piramis-base-components/src/models/CheckChannels'
import { NodeTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes'
import { RandomizerNodeBuilder } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/RandomizerNode'

import { instanceToPlain } from 'class-transformer'
import { default as Vuei18n } from 'vue-i18n'
import * as Sentry from '@sentry/browser'

export default class Editor extends CustomEditor {
  statistics = new PresscodeFlowStatistics(this.view)

  async getUsersInfoRequest(users: Array<string>): Promise<GetUsersInfo> {
    return FlowsService.newGetUsersInfo('tg', { board_key: store.state.boardsState.activeBoard!.board, values: users })
  }

  getChannelsInfoRequest(channels: Array<string>): Promise<ChannelInfoResponse> {
    return FlowsService.getChannelInfo('tg', { board_key: store.state.boardsState.activeBoard!.board, values: channels })
  }

  scheme() {
    throw Error()
  }

  async saveFlow(): Promise<void> {
    try {
    return await FlowsService.updateFlow('tg', {
      board_key: store.state.boardsState.activeBoard!.board,
      flow_key: this.flowConfig.key,
      title: this.flowConfig.data.title,
      scheme: instanceToPlain(this.getFlowConfigModel())
    })
        .then(() => {
          successNotification(i18n.t('save_flow_success').toString())
          this.flowBackup = instanceToPlain(this.getFlowConfigModel())
        })
        .catch(errorNotification)
    } catch (e) {
      if (e instanceof Error) {
        errorNotification(e.message)

        Sentry.captureException(e, {
          extra: {
            schema: JSON.stringify(this.getFlowConfigModel())
          }
        })
      }
    }
  }

  projectState = state.boardsState.activeBoard!

  nodesBuilders

  readonly = router.currentRoute.name === 'Flow_Screenshot'

  flowSettingsController = {
    renameFlow: async ({ title, key }: { title: string, key: string }) => {
      try {
        const { flow } = await FlowsService.renameFlow('tg', { board_key: store.state.boardsState.activeBoard!.board, title: title, key })
        this.flowConfig.data.title = flow.data.title
        return
      } catch (e) {
        errorNotification(e)
      }
    },
    flowMainHelpMessage: FlowMainHint,
    startTesting: () => {
      FlowsService.runTestingFlow('tg', { board_key: store.state.boardsState.activeBoard!.board, flow_key: this.flowConfig.key })
    }
  }

  constructor(config: ProjectFlowConfig, container: HTMLElement, i18n: Vuei18n) {
    super(container, config, i18n)

    this.nodesBuilders = {
      [NodeTypes.SendMessage]: new SendMessageNode(i18n, this.readonly),
      [NodeTypes.SendInvoice]: new SendInvoiceNode(i18n, this.readonly),
      [NodeTypes.EditLastMessage]: new EditLastMessageNode(i18n, this.readonly),
      [NodeTypes.Action]: new ActionNode(i18n, this.readonly),
      [NodeTypes.ForwardMessage]: new ForwardMessageNode(i18n, this.readonly),

      [NodeTypes.Randomizer]: new RandomizerNodeBuilder(i18n),
      [NodeTypes.Conditions]: new ConditionsCustomNodeBuilder(i18n, this.readonly),
      [NodeTypes.Delay]: new DelayNode(i18n, this.readonly),
      [NodeTypes.CheckSubscription]: new CheckSubscriptionNode(i18n, this.readonly),

      [NodeTypes.CheckLimitedSubscription]: new CheckLimitedSubscriptionNode(i18n, this.readonly),
      [NodeTypes.CreateLimitedSubscription]: new CreateLimitedSubscriptionCustomNodeBuilder(i18n, this.readonly),
      [NodeTypes.InfoLimitedSubscription]: new InfoLimitedSubscriptionNode(i18n, this.readonly),
      [NodeTypes.HttpRequest]: new HttpRequestNodeCustomBuilder(i18n, this.readonly),
      [NodeTypes.Integration]: new IntegrationNodeBuilderCustom(i18n),

      [NodeTypes.RunFlow]: new RunFlowNode(i18n, this.readonly),
    }
  }
}