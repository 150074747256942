var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"join-request-sidebar"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action,
        'key': 'targets',
        'options': _vm.action.nodeProps.targetOptions,
        'validation': 'required',
        'prefix': 'join_request_',
        'multiple': true
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action,
        'key': 'mode',
        'options': _vm.action.modeOptions,
        'validation': 'required',
        'prefix': 'join_request_',
        'clearable': false
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }