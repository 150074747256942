








import { DeleteLastMessageActionProxy } from '../../logic/DeleteLastMessageActionProxy'

import SampleNodeMixin from 'piramis-base-components/src/components/Flow/new/logic/Nodes/SampleNodeMixin'
import ActionNode
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
})
export default class DeleteLastMessageActionNode extends Mixins(SampleNodeMixin) {
  @Prop() action!: DeleteLastMessageActionProxy

  @Prop() node!: ActionNode
}
