import store from '@/store/store'

import { HttpRequestNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Additionally/HttpRequest/logic/types'
import HttpRequestNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Additionally/HttpRequest/logic/HttpRequestNode'

import i18n from 'vue-i18n'

export class HttpRequestNodeCustomBuilder extends HttpRequestNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: HttpRequestNodeProps

    if (readonly) {
      props = {
        properties: () => [],
      }
    } else {
      props = {
        properties: () => store.getters.propertiesList,
      }
    }

    super(i18n, props)
  }

}