import { JoinRequestMode } from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestActionProxy'

import ActionNodeAction from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions'

import { Exclude, plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'

export class JoinRequestAction extends ActionNodeAction<JoinRequestAction> {
    declare type: ActionTypes.JoinRequest

    targets!: Array<number>

    mode!: JoinRequestMode

    @Exclude()
    guid: string = Guid.create().toString()

    getNewModel() {
        return plainToInstance(JoinRequestAction, {
            type: ActionTypes.JoinRequest,
            targets: [],
            mode: JoinRequestMode.Accept
        })
    }
}
