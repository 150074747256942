var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prop-select-view"},[_c('select-input',{attrs:{"setup":{
      func: _vm.modelSetter,
      args: {
        model: this,
        key: 'value',
        options: _vm.properties,
        taggable: true,
        clearable: false,
        prefix: 'property_select_',
        createButton: {
          title: _vm.$t('create_property_button').toString(),
          callback: _vm.createNewPropertyHandler,
        },
      }
    }},on:{"input":_vm.onInput}}),_c('create-new-property',{attrs:{"is-open":_vm.isOpen,"init-name":_vm.newPropertyName,"init-type":_vm.PropertyFieldType.Files,"create-new-property":_vm.createNewProperty},on:{"update:isOpen":function($event){_vm.isOpen=$event},"update:is-open":function($event){_vm.isOpen=$event},"setProperty":_vm.setPropertyIndex}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }