


























































































































import { AccessorCondition } from "@/components/Flow/Accessor/Conditions/AccessorCondition";
import { AccessorTypesEnum } from "@/components/Flow/Accessor/Conditions/types";
import { AccessorConditionBuilder } from "@/components/Flow/Accessor/Conditions/AccessorConditionBuilder";
import { errorNotification } from "@/includes/services/NotificationService";
import { FactoryBuilderClass } from "@/components/Flow/Accessor/Conditions/includes";
import Editor from "@/components/Flow/Editor";
import ConditionItemActions from "@/components/Flow/Accessor/components/ConditionItemActions.vue";

import { IWizardLikeListItem, WizardLikeList } from "piramis-base-components/src/components/WizardLikeSelect/types";
import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import WizardLikeSelect from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue";

import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { cloneDeep } from "lodash";

type CurrentCondition = {
  groupGuid?: string
  conditionGuid?: string
  condition?: AccessorConditionBuilder
  _condition?: AccessorConditionBuilder
}

type FrontendConditions = Array<IterableListItem<Array<IterableListItem<AccessorConditionBuilder>>>>

@Component({
  components: {
    ConditionItemActions,
    WizardLikeSelect,
    Icon
  }
})
export default class FlowAccessorConditions extends Vue {

  @Prop() conditions!: Array<Array<AccessorCondition>>

  @Prop() editor!: Editor

  @Emit()
  updateConditions(conditions: FrontendConditions) {
    return this.frontendToRaw(conditions)
  }

  @Watch('conditions', { deep: true, immediate: true })
  onInputConditionsChange(conditions: Array<Array<AccessorCondition>> | undefined) {
    if (conditions) {
      this.frontendConditions = this.rawToFrontend(conditions)
    }
  }

  conditionBuilders = Object.keys(AccessorTypesEnum)
    .reduce((acc: Partial<Record<AccessorTypesEnum, AccessorConditionBuilder>>, value) => {
      const builder = FactoryBuilderClass.getBuilder(value as AccessorTypesEnum)

      if (builder) {
        acc[value] = builder
      }

      return acc
    }, {})

  isModalOpen = false

  @Watch('isModalOpen')
  onIsModalOpenChange(state: boolean) {
    if (!state) {
      this.frontendConditions = this.frontendConditions.filter(g => g.value.length)
    }
  }

  iterableItemFactory = new IterableItemFactory()

  frontendConditions: FrontendConditions = []

  currentCondition: CurrentCondition | null = null

  get items(): WizardLikeList {
    const items: Array<IWizardLikeListItem> = Object.values(this.conditionBuilders)
      .map(b => {
        return {
          key: b.model.type,
          title: this.$t(b.titleKey()).toString(),
          helpMessage: this.$te(b.helpMessageKey()) ? this.$t(b.helpMessageKey()).toString() : null,
          icon: b.icon
        }
      })

    return [
      {
        type: "Simple",
        items
      }
    ]
  }

  addGroup() {
    const group = this.iterableItemFactory.create([])

    this.frontendConditions.push(group)
    this.currentCondition = {
      groupGuid: group.guid
    }

    this.isModalOpen = true
  }

  setCurrentBuilder(type: AccessorTypesEnum) {
    if (this.currentCondition) {
      if (!this.currentCondition.conditionGuid || this.currentCondition._condition?.model?.type !== type) {
        this.$set(this.currentCondition, 'condition', cloneDeep(this.conditionBuilders[type]))
      } else {
        this.$set(this.currentCondition, 'condition', cloneDeep(this.currentCondition._condition))
      }
    }
  }

  editItem(condition: IterableListItem<AccessorConditionBuilder>, groupGuid: string) {
    this.currentCondition = { groupGuid, conditionGuid: condition.guid }

    this.$set(this.currentCondition, 'condition', condition.value)
    this.$set(this.currentCondition, '_condition', condition.value)

    this.isModalOpen = true
  }

  removeGroup(groupGuid: string) {
    this.frontendConditions = this.frontendConditions.filter(g => g.guid !== groupGuid)

    this.updateConditions(this.frontendConditions)
  }

  removeItem(itemGuid: string, groupGuid: string) {
    const g = this.frontendConditions.find(g => g.guid === groupGuid)

    if (g) {
      g.value = g.value.filter(c => c.guid !== itemGuid)

      if (!g.value.length) {
        this.frontendConditions = this.frontendConditions.filter(g => g.guid !== groupGuid)
      }

      this.updateConditions(this.frontendConditions)
    }
  }

  applyCondition() {
    if (this.currentCondition && this.currentCondition.condition) {
      const group = this.frontendConditions.find(g => g.guid === this.currentCondition?.groupGuid)

      if (group) {
        if (this.currentCondition.conditionGuid) {
          const item = group.value.find(i => i.guid === this.currentCondition!.conditionGuid)

          if (item) {
            item.value.updateModel(this.currentCondition.condition.model)
          }
        } else {
          group.value.push(this.iterableItemFactory.create(this.currentCondition.condition))
        }

        this.currentCondition = null;
      }
    }

    this.updateConditions(this.frontendConditions)
  }

  async saveItem(_: IWizardLikeListItem['key'] | null, closeModalCallback: () => void) {
    if (this.currentCondition) {
      try {
        const res = await this.currentCondition.condition?.model?.validate()

        if (res) {
          this.applyCondition()

          closeModalCallback()
        }
      } catch (e: unknown) {
        errorNotification(e)
      }
    }
  }

  rawToFrontend(raw: Array<Array<AccessorCondition>>) {
    return raw.map(g => this.iterableItemFactory.create(g.reduce((acc: Array<IterableListItem<AccessorConditionBuilder>>, value) => {
      const builder = cloneDeep(this.conditionBuilders[value.type])

      if (builder) {
        builder.updateModel(value)

        acc.push(this.iterableItemFactory.create(builder))
      }

      return acc
    }, [])))
  }

  frontendToRaw(conditions: FrontendConditions) {
    return conditions.map(g => g.value.map(i => i.value.model))
  }

  openSettingModal(groupGuid: string) {
    this.currentCondition = { groupGuid }

    this.isModalOpen = true
  }
}

