import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {
	PlaceholdersStack,
	PlaceholdersType
} from 'piramis-base-components/src/components/Editor/components/Toolbar/types'
import i18n from '@/i18n/i18n'

@Component
export default class PlaceholdersMixin extends Vue {
	get botFeedbackPlaceholders(): PlaceholdersStack {
		return {
				type: PlaceholdersType.Stack,
				placeholders: [
					{
						sections: [
							{
								buttonTitle: 'UTM',
								placeholders: [
									{
										label: this.$t('placeholder_utm_invite_channel').toString(),
										value: '{utm_invite_channel}'
									},
									{
										label: this.$t('placeholder_utm_invite_link').toString(),
										value: '{utm_invite_link}'
									},
									{
										label: this.$t('placeholder_utm_info').toString(),
										value: '{utm_info}'
									},
									{
										label: this.$t('placeholder_utm').toString(),
										value: '{utm}'
									},
									{
										label: this.$t('placeholder_joinlink').toString(),
										value: '{joinlink}',
									}
								]
							},
							{
								buttonTitle: this.$t('placeholder_button_title_end_subscription').toString(),
								placeholders: [
									{
										label: this.$t('placeholder_end_date_time').toString(),
										value: '{enddatetime}'
									},
									{
										label: this.$t('placeholder_end_date').toString(),
										value: '{enddate}'
									},
									{
										label: this.$t('placeholder_end_time').toString(),
										value: '{endtime}'
									},
								]
							},
							{
								buttonTitle: this.$t('placeholder_button_title_user').toString(),
								placeholders: [
									{
										label: this.$t('placeholder_user_id').toString(),
										value: '{user_id}'
									},
									{
										label: this.$t('placeholder_username').toString(),
										value: '{username}'
									},
									{
										label: this.$t('user_placeholder_userlogin').toString(),
										value: '{userlogin}'
									},
								]
							},
							{
								buttonTitle: i18n.t('bot_invite_links_placeholders').toString(),
								placeholders: [
									{
										label: i18n.t('bot_referral_link').toString(),
										value: '{referral_link}'
									},
								]
							},
						]
					},
				]
			}
	}
}
