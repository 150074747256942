import i18n from 'vue-i18n'
import ConditionsNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/ConditionsNode/logic/ConditionsNode'
import { ConditionsNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/ConditionsNode/logic/types'
import store from '@/store/store'

export class ConditionsCustomNodeBuilder extends ConditionsNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: ConditionsNodeProps

    if (readonly) {
      props = {
        createNewProperty: (path: string, name: string, parent?: number) => store.dispatch('addBoardProperty', { path, name, parent } ),
        createNewLabel: (label) => store.dispatch('createLabel', label),
        properties: () => [],
        labels: () => {
          return {}
        },
      }
    } else {
      props = {
        createNewProperty: (path: string, name: string, parent?: number) => store.dispatch('addBoardProperty', { path, name, parent } ),
        createNewLabel: (label) => store.dispatch('createLabel', label),
        properties: () => store.getters.propertiesList,
        labels: () => store.getters.labels,
      }
    }

    super(i18n, props)
  }
}
