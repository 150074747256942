
































import { InputSetups } from '@/mixins/input-setups'
import { JoinRequestActionProxy } from '../../logic/JoinRequestActionProxy'

import CustomEditor from 'piramis-base-components/src/components/Flow/new/logic/Editor/CustomEditor'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
})
export default class JoinRequestSidebar extends Mixins(UseFields, InputSetups, ModelSetter) {
  @Prop() action!: JoinRequestActionProxy

  @Prop() editor!: CustomEditor
}
