import { DeleteLastMessageAction } from "./DeleteLastMessageAction"

import { ActionNodeActionProxy } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions"
import { ActionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/types'

export class DeleteLastMessageActionProxy extends ActionNodeActionProxy {
    action: DeleteLastMessageAction
    private nodeProps: ActionNodeProps

    constructor(action: DeleteLastMessageAction, nodeProps: ActionNodeProps) {
        super()
        this.nodeProps = nodeProps
        this.action = action
    }

    getModel(): DeleteLastMessageAction {
        return this.action
    }
}