




import { DeleteLastMessageActionProxy } from '../../logic/DeleteLastMessageActionProxy'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
})
export default class DeleteLastMessageActionSidebar extends Vue {
  @Prop() action!: DeleteLastMessageActionProxy
}
