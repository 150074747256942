import { Style } from '@/includes/types/Post.types'

import ActionNodeAction
    from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions'
import {
    ActionTypes
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'
import MessageEditorWithMediaData
    from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import {
    BaseDelayType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/BaseDelayType'
import {
    DelayType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/types'
import { DelayPropertyType } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/DelayPropertyType'
import { DelayDateType } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/DelayDateType'
import { DelayIntervalType } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/DelayIntervalType'

import { Exclude, plainToInstance, Type } from 'class-transformer'
import { Guid } from 'guid-typescript'

export class PublishAction extends ActionNodeAction<PublishAction> {
    declare type: ActionTypes.Publish

    targets!: Array<number>

    @Type(() => MessageEditorWithMediaData)
    message!: MessageEditorWithMediaData

    @Type(() => BaseDelayType, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'type',
            subTypes: [
                { name: DelayType.Interval, value: DelayIntervalType },
                { name: DelayType.Date, value: DelayDateType },
                { name: DelayType.Property, value: DelayPropertyType },
            ]
        }
    })
    delay!: BaseDelayType | null

    data!: {
        style?: Style | null
        style_id: Style['id'] | null
    }
    
    @Exclude()
    guid: string = Guid.create().toString()

    getNewModel() {
        return plainToInstance(PublishAction, {
            type: ActionTypes.Publish,
            targets: [],
            message: {
                attachments: [],
                text: '',
                buttons: [],
                remove_previous: false,
                pin: false,
                disable_link_preview: false,
                disable_notify: false,
            },
            delay: {
                type: DelayType.Interval,
                offset: 0,
                limit: null
            },
            data: {
                style: null
            }
        })
    }
}
