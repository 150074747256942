var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-invite-link-sidebar"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action,
        'key': 'channel_id',
        'options': _vm.$store.getters.boardTargetOptions,
        'prefix': 'create_invite_link_',
        'clearable': false,
        'validation': 'required'
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action,
        'key': 'property_id',
        'options': _vm.properties,
        'prefix': 'create_invite_link_',
        'clearable': false,
        'validation': 'required'
      }
    }}}),_c('div',{staticClass:"relative"},[_c('transition',{attrs:{"name":"default-hide","mode":"out-in"}},[(_vm.action.action.config.approve !== _vm.InviteLinkApproveType.None)?_c('a-alert',{staticClass:"absolute z-10 w-full h-full ",staticStyle:{"opacity":"0.9"},attrs:{"type":"warning","show-icon":""}},[_c('p',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" "+_vm._s(_vm.$t('invite_linc_disabled_limit_alert_text'))+" ")])]):_vm._e()],1),_c('number-input',{staticClass:"h-full",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.action.action.config,
          'key': 'limit',
          'prefix': 'invite_link_model_',
          'min': 0,
          'disabled': _vm.action.action.config.approve !== _vm.InviteLinkApproveType.None,
        }
      }}})],1),_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action.config,
        'key': 'disable_auto_attack',
        'prefix': 'invite_link_model_',
      },
    }}}),_c('date-picker-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action.action.config,
        'key': 'expire',
        'clearable': true,
        'format': _vm.PickerFormat.DateTime,
        'prefix': 'invite_link_',
      }
    }}}),(_vm.isChannelPrivate)?_c('div',{staticClass:"my-5"},[_c('a-button',{staticClass:"btn-wrap",class:{
        'active': _vm.isConfigChanged
      },attrs:{"block":""},on:{"click":_vm.handleApproveSettingsButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('invite_link_approve_setting_button_title'))+" ")])],1):_vm._e(),_c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"destroy-on-close":"","title":_vm.$t('invite_link_approve_setting_button_title'),"width":"100%","centered":"","ok-text":_vm.$t('accept')},on:{"cancel":_vm.onCancel,"ok":function($event){_vm.isExtraSettingModalOpen = false}},model:{value:(_vm.isExtraSettingModalOpen),callback:function ($$v) {_vm.isExtraSettingModalOpen=$$v},expression:"isExtraSettingModalOpen"}},[_c('invite-link-action-setting',{attrs:{"approve-type-options":_vm.approveTypeOptions,"channel-id":_vm.action.action.channel_id},model:{value:(_vm.action.action.config),callback:function ($$v) {_vm.$set(_vm.action.action, "config", $$v)},expression:"action.action.config"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }