

































import store from '@/store/store'
import { ChannelInfo } from '@/includes/types/Board.types'
import { CreateInviteLinkActionProxy } from '@/components/Flow/Actions/CreateInviteLink/logic/CreateInviteLinkActionProxy'

import SampleNodeMixin from 'piramis-base-components/src/components/Flow/new/logic/Nodes/SampleNodeMixin'
import ButtonView from 'piramis-base-components/src/components/Flow/new/components/NodeButtonsView/ButtonView.vue'
import { SocketNames } from 'piramis-base-components/src/components/Flow/new/components/Socket/SocketsHelper'
import findPropertyById from 'piramis-base-components/src/components/Flow/new/logic/Helpers/findPropertyById'
import { getNewPropertyIcon, getPropertyTranslation } from 'piramis-base-components/src/components/BotProperties/utils'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import ActionNode from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ButtonView,
    Icon
  },
  data() {
    return {
      SocketNames,
    }
  }
})
export default class CreateInviteLinkNode extends Mixins(SampleNodeMixin) {
  @Prop() action!: CreateInviteLinkActionProxy

  @Prop() node!: ActionNode

  get channel(): ChannelInfo | undefined {
    return store.state.boardsState.activeBoard?.channels.find((t) => t.id === this.action.action.channel_id)
  }

  get channelErrorTitle() {
    if (!this.action.action.channel_id) {
      return this.i18n.t('flow_select_channel').toString()
    } else if (!this.channel) {
      return this.i18n.t('channel_not_found').toString()
    } else {
      return ''
    }
  }

  get channelTitle(): string {
    if (this.channel) {
      return this.channel.title
    }

    return this.i18n.t('channel_not_found').toString()
  }

  get property() {
    return findPropertyById(store.state.boardsState.activeBoard?.properties ?? [], this.action.action.property_id)
  }

  get propertyErrorTitle() {
    if (!this.action.action.channel_id) {
      return this.i18n.t('field_set_property_title').toString()
    } else if (!this.property) {
      return this.i18n.t('flow_property_not_found').toString()
    }
  }

  get propertyTitle(): string {
    if (this.property) {
      return getPropertyTranslation(this.i18n, this.property.name ?? this.property.type)
    }

    return this.i18n.t('flow_property_not_found').toString()
  }

  get propertyIcon() {
    if (this.property) {
      return getNewPropertyIcon(this.property.path ?? this.property.type)
    }

    return ''
  }
}
