import store from '@/store/store'

import { CustomMediaFileData } from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/logic/MediaFileData";

export default class PropertiesMediaFileData extends CustomMediaFileData {
    static subType = 'CustomProperty'
    static iconKey = 'container'

    icon = PropertiesMediaFileData.iconKey

    constructor() {
        super();
    }

    get fileName() {
        if (this.file) {
            const index = Number.parseInt(this.file.split(':')[1])

            const property = store.state.boardsState.activeBoard?.properties.find(p => p.id === index)

            if (property) {
                return `Свойство ${ property.name }`
            }

            return `Свойство ${ index }`
        }

        return this.type
    }

    get exactType() {
        return PropertiesMediaFileData.subType
    }
}
