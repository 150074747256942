
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

@Component({})
export default class AvatarsRow extends Vue {
  @Prop() list!: Array<SelectOptionData>

  @Prop({ type: Number, default: 30 }) avatarsSize!:number
}
