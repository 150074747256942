



















import {
  JoinRequestActionProxy,
  JoinRequestMode
} from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestActionProxy'
import store from '@/store/store'

import SampleNodeMixin from 'piramis-base-components/src/components/Flow/new/logic/Nodes/SampleNodeMixin'
import ActionNode
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
})
export default class JoinRequestNode extends Mixins(SampleNodeMixin) {
  @Prop() action!: JoinRequestActionProxy

  @Prop() node!: ActionNode

  get channelTitles() {
    return store.getters.activeBoardChannels
      .filter(c => this.action.action.targets.includes(c.id))
      .map(c => c.title)
      .join(', ')
  }

  get nodeTitle() {
    const { mode } = this.action.action

    if (mode === JoinRequestMode.Accept) {
      return this.i18n.t('join_request_action_accept_node_title', [ this.channelTitles ])
    } else {
      return this.i18n.t('join_request_action_reject_node_title', [ this.channelTitles ])
    }
  }
}
