import store from '@/store/store'
import CashRegisterHelper from '@/views/shop/CashRegisterHelper'

import SendInvoiceNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/SendInvoice/logic/SendInvoiceNode'
import { SendInvoiceNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/SendInvoice/logic/types'
import { ProductState } from 'piramis-base-components/src/components/Shop/Products/types'
import { CashRegistersState } from 'piramis-base-components/src/components/Shop/CashRegisters/types'

import { cloneDeep } from 'lodash'
import i18n from 'vue-i18n'

export class SendInvoiceNode extends SendInvoiceNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {

    let props: SendInvoiceNodeProps

    if (readonly) {
      props = {
        getCashRegisters: () => [],
        getProducts: () => [],
        createProduct: () => {
          throw new Error('Attempt to create a product with a screenshot')
        },
        createNewCashRegister: () => {
          throw new Error('Attempt to create a payment system with a screenshot')
        },
        getProperties: () => [],
        target: '',
        cashRegisterLogic: null
      }
    } else {
      props = {
        getCashRegisters: () => store.getters.getCashRegister
            .filter(register => register.state === CashRegistersState.Active),
        getProducts: () => store.getters.getProducts.filter(p => p.state === ProductState.Active),
        createProduct: (product) => store.dispatch('createProduct', product),
        cashRegisterLogic: new CashRegisterHelper(cloneDeep(store.state.shopState.cashRegisters ?? [])),
        createNewCashRegister: (system) => store.dispatch('createCashRegister', system),
        getProperties: () => store.getters.propertiesList,
        target: store.state.boardsState.activeBoard!.board,
      }
    }

    super(i18n, props)
  }
}