







































































































































import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { InputSetups } from '@/mixins/input-setups'
import InviteLinkActionSetting from '@/components/InviteLinks/InviteLinkActionSetting.vue'
import { CreateInviteLinkActionProxy } from '../../logic/CreateInviteLinkActionProxy'
import { InviteLinkApproveType, InviteLink } from '@/includes/logic/InviteLinks/types'

import { getNewPropertyIcon, getPropertyTranslation } from 'piramis-base-components/src/components/BotProperties/utils'
import CustomEditor from 'piramis-base-components/src/components/Flow/new/logic/Editor/CustomEditor'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { PickerFormat } from 'piramis-base-components/src/components/Picker/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    InviteLinkActionSetting,
  },
  data() {
    return {
      InviteLinkApproveType,
      PickerFormat,
      MediaEditorMode,
      EditorButtonsHelpView
    }
  },
})
export default class CreateInviteLinkSidebar extends Mixins(UseFields, InputSetups) {
  @Prop() action!: CreateInviteLinkActionProxy

  @Prop() editor!: CustomEditor

  isExtraSettingModalOpen = false

  linkConfigBackup: InviteLink['config'] | null = null

  get isChannelPrivate() {
    return !this.$store.state.boardsState.activeBoard!.channels.find(t => t.id === this.action.action.channel_id)?.username
  }

  get isConfigChanged() {
    return this.action.action.config.approve !== InviteLinkApproveType.None
  }

  get approveTypeOptions(): Array<SelectOptionData> {
    return Object.values(InviteLinkApproveType).map((type) => {
      return {
        label: this.$t(`invite_link_approve_type_${ type.toLowerCase() }`).toString(),
        value: type
      }
    })
  }

  get properties(): Array<SelectOptionData> {
    return this.$store.state.boardsState.activeBoard.properties
      .filter(p => p.path === 'InviteLink')
      .map(p => {
        return {
          label: getPropertyTranslation(this.$i18n, p.name ?? p.type),
          value: p.id,
          icon: {
            iconPack: 'material-icons',
            name: getNewPropertyIcon(p.path ?? p.type)
          }
        }
      })
  }

  handleApproveSettingsButtonClick() {
    this.linkConfigBackup = cloneDeep(this.action.action.config)

    this.isExtraSettingModalOpen = true
  }

  onCancel() {
    if (this.linkConfigBackup) {
      this.action.action.config = cloneDeep(this.linkConfigBackup)
    }

    this.linkConfigBackup = null
  }

}
