import i18n from 'vue-i18n'
import CreateLimitedSubscriptionNodeBuilder
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Subscription/CreateLimitedSubscription/logic/CreateLimitedSubscription'
import { CreateLimitedSubscriptionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Subscription/CreateLimitedSubscription/logic/types'
import store from '@/store/store'
import router from '@/router'
import { ChannelService } from '@/includes/services/ChannelService'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { ChannelLimitedTypeEnum } from 'piramis-base-components/src/shared/types/Channel'

export class CreateLimitedSubscriptionCustomNodeBuilder extends CreateLimitedSubscriptionNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: CreateLimitedSubscriptionNodeProps

    if (readonly) {
     props = {
       targets: () => [],
       getChannelSubscriptionType: () => Promise.resolve(ChannelLimitedTypeEnum.None),
       getChannelSubscriptionLink: () => '',
     }
    } else {
    props = {
      getChannelSubscriptionLink: (id) => router.resolve({ name: 'Channel_subscription', params: {
          [ EntityTypes.BOT_ID ]: router.currentRoute.params[ EntityTypes.BOT_ID ],
          [ EntityTypes.CHANNEL_ID ]: id.toString()
        } }).href,
      getChannelSubscriptionType: async (id) => {
        const { limited_subscription_type } = await ChannelService.getChannelConfig('tg', {
          board_key: store.state.boardsState.activeBoard!.board,
          channel_id: id
        })

        return limited_subscription_type as unknown as ChannelLimitedTypeEnum
      },
      targets: () => {
        const board = store.state.boardsState.activeBoard

        if (board) {
          return store.getters.activeBoardChannels.map(({ title, username, id }) => ({
            title,
            id,
            login: username
          }))
        }
        return []
      }
    }
    }

    super(i18n, props)
  }
}