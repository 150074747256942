import ForwardMessageNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/ForwardMessage/logic/ForwardMessageNode'
import { ForwardMessageNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/ForwardMessage/logic/types'
import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import i18n from 'vue-i18n'
import store from '@/store/store';
import customMediaTypes from '@/components/MessageEditorCustomAttachments/Properties/customMediaTypes'

export class ForwardMessageNode extends ForwardMessageNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: ForwardMessageNodeProps

    if (readonly) {
      props = {
        admins: [],
        targets: [],
        target: '',
      }
    } else {
    props = {
      customMediaTypes: customMediaTypes,
      admins: store.state.boardsState.activeBoard!.admins
        .slice()
        .filter(a => a.limits === null)
        .map(a => ({
          value: a.user_id,
          label: `${ a.name } ${ a.username ? '(' + atSignedLogin(a.username) + ')' : '' }`
        })),
      targets: store.getters.activeBoardChannels
          .map(t => ({
            value: t.id,
            label: t.title,
              image: {
                src: t.avatar
              }
          })),

      target: store.state.boardsState.activeBoard!.board,
    }
    }

    super(i18n, props)
  }

}