import { InputSetups } from "@/mixins/input-setups";

import {
    ButtonBuildersGroups
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types";
import {
    ButtonTypes
} from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/types";
import MessageEditorWithMediaData
    from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Component, Mixins } from "vue-property-decorator";

@Component({})
export default class CaptchaMessageHelper extends Mixins(InputSetups) {
    autoApproveButtons(message: Array<MessageEditorWithMediaData>) {
        if (message && message.every(m => !m.buttons?.length || m.buttons.every(r => !r.length))) {
            return [
                new ButtonBuildersGroups(this.$t('editor_buttons_list_group_common').toString(), this.newCaptchaButton),
                new ButtonBuildersGroups(this.$t('editor_buttons_list_group_inline').toString(),this.newPmButtons),
            ]
        }

        if (this.hasCaptchaButton(message)) {
            return this.newCaptchaButton
        } else {
            return this.newPmButtons
        }
    }

    hasCaptchaButton(message: Array<MessageEditorWithMediaData>) {
        return message && message
            .some(m => m.buttons && m.buttons.length && m.buttons.some(r =>
                r.length && r.some(b => b.type === ButtonTypes.Text)))
    }
}
