import { JoinRequestAction } from "./JoinRequestAction"

import { ActionNodeActionProxy } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions"
import { ActionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import i18n from '@/i18n/i18n'
import { snakeCase } from 'lodash'

export class JoinRequestActionProxy extends ActionNodeActionProxy {
    action: JoinRequestAction
    private nodeProps: ActionNodeProps

    constructor(action: JoinRequestAction, nodeProps: ActionNodeProps) {
        super()
        this.nodeProps = nodeProps
        this.action = action
    }

    getModel(): JoinRequestAction {
        return this.action
    }

    modeOptions: Array<SelectOptionData> = Object
        .values(JoinRequestMode)
        .map(value => ({
            label: i18n.t(`${ snakeCase(value) }_join_request_mode_title`).toString(),
            value
        }))

    isActionValid(): boolean {
        return this.action.targets.length > 0
    }
}

export enum JoinRequestMode {
    Accept = 'Accept',
    Reject = 'Reject'
}
