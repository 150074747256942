import { plainToInstance } from 'class-transformer'
import PropertiesMediaFileData from '@/components/MessageEditorCustomAttachments/Properties/PropertiesMediaFileData'
import { FileType } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/Media/types'
import PropertiesSelectView from '@/components/MessageEditorCustomAttachments/Properties/PropertiesSelectView.vue'

const customMediaTypes = {
    classParser: (file) => {
        return plainToInstance(PropertiesMediaFileData, {
            file: file.file,
            type: file.type,
        })
    },
    types: [
        {
            type: FileType.Custom,
            subType: PropertiesMediaFileData.subType,
            icon: PropertiesMediaFileData.iconKey,
            view: PropertiesSelectView,
        }
    ]
}

export default customMediaTypes