import CheckSubscriptionNodeBuilder
    from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/CheckSubscriptionNode/logic/CheckSubscriptionNode'
import { CheckSubscriptionNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/CheckSubscriptionNode/logic/types'
import i18n from 'vue-i18n'
import store from '@/store/store'
import { TargetState } from '@/includes/types/Board.types'

export class CheckSubscriptionNode extends CheckSubscriptionNodeBuilder {
    constructor(i18n: i18n, readonly: boolean) {
        let props: CheckSubscriptionNodeProps

        if (readonly) {
            props = {
                targets: () => []
            }
        } else {
            props = {
                targets: () => {
                    const board = store.state.boardsState.activeBoard

                    if (board) {
                        return board.channels
                            .filter(c => c.state === TargetState.Board)
                            .map(({ title, username, id }) => ({
                                title,
                                id,
                                login: username
                            }))
                    }

                    return []
                }
            }
        }

        super(i18n, props)
    }
}