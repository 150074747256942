import { BanAction } from './BanAction'

import { ActionNodeActionProxy } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions"

export class BanActionProxy extends ActionNodeActionProxy {
    action: BanAction

    constructor(action: BanAction) {
        super()
        this.action = action
    }

    getModel(): BanAction {
        return this.action
    }
}