import { InviteLinkApproveType, InviteLink } from '@/includes/logic/InviteLinks/types'

import ActionNodeAction from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'

import { Exclude, plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export class CreateInviteLinkAction extends ActionNodeAction<CreateInviteLinkAction> {
    declare type: ActionTypes.CreateInviteLink

    channel_id!: null | number

    property_id!: null | number

    config!: InviteLink['config']

    @Exclude()
    guid: string = Guid.create().toString()

    getNewModel() {
        return plainToInstance(CreateInviteLinkAction, {
            type: ActionTypes.CreateInviteLink,
            channel_id: null,
            property_id: null,
            config: {
                approve: InviteLinkApproveType.None,
                price: 0,
                limit: 0,
                disable_auto_attack: false,
                force_approve: true,
            }
        })
    }
}
