import ActionNodeAction from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'

import { Exclude, plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export class DeleteLastMessageAction extends ActionNodeAction<DeleteLastMessageAction> {
    declare type: ActionTypes.DeleteLastMessageAction

    @Exclude()
    guid: string = Guid.create().toString()

    getNewModel() {
        return plainToInstance(DeleteLastMessageAction, {
            type: ActionTypes.DeleteLastMessageAction,
        })
    }
}
